import {
  ROOM_RANKING_TYPE_CITYBREAK,
  ROOM_RANKING_TYPE_EPI,
  ROOM_CITYBREAK_RANKINGS,
} from "@gdf/resources/src/constants/room";

// helpers
import roomIsRankingValid from "../isRankingValid";

/**
 * Retourne le nombre d’épi associé à un ranking de type citybreak
 * @param {object} param0
 * @param {object} param0.rom
 * @return {}
 */
export default function roomMapRankingCitybreakToEpi({ room }) {
  if (roomIsRankingValid({ room })) {
    switch (room.ranking.type) {
      case ROOM_RANKING_TYPE_CITYBREAK: {
        return ROOM_CITYBREAK_RANKINGS[room.ranking.value].epi;
      }

      case ROOM_RANKING_TYPE_EPI: {
        return room.ranking.value;
      }
    }
  }
  return null;
}

type RoomHasLastSeenRoomParam0Type = {
  preventRoomReference?: string;
  roomToAdd?: ReturnType<
    typeof import("@gdf/resources/src/normalizers/room").roomParseData
  >;
};

/**
 * Retourne vrai s'il existe des hébergments récemment visités.
 */
const roomHasLastSeenRoom = ({
  preventRoomReference = null,
  roomToAdd = null,
}: RoomHasLastSeenRoomParam0Type = {}): boolean => {
  if (process.browser) {
    if (roomToAdd) {
      // Si on doit ajouter un hébergement, on force le fait qu'il faille
      //  rendre le composant.
      return true;
    }

    const rawRoomList = localStorage.getItem("lastSeenRoomList");

    // Stocke dans un objet normalisé les hébergements récents.
    const storageLastSeenRoomList = rawRoomList ? JSON.parse(rawRoomList) : [];

    return (
      storageLastSeenRoomList.filter(
        (room) => room.reference !== preventRoomReference
      ).length > 0
    );
  }

  return false;
};

export default roomHasLastSeenRoom;

// dep
import React from "react";

// constant
import { SEO_PAGE_VALUE_ROOM } from "@gdf/resources/src/constants/seo";
import { ROOM_TYPES } from "@gdf/resources/src/constants/room";
import { DEPARTMENT_LIST } from "@gdf/resources/src/constants/department";

// helpers
import getContentRelayOnConfiguration from "./getContentRelayOnConfiguration";

/**
 * Détermine les informations en fonction d'une Room
 * @param configuration
 * @param page
 */
export default function seoGetContentRelayOnRoom({
  room,
  configuration,
  intl,
}) {
  const seoContentList = [];
  // Title doit être de la forme : Location $type à $city (region)
  let title = undefined;
  // Desc doit être de la forme : Découvrez notre $type à $city avec une capacité d'accueil de $maxCapacity personne%s en plein coeur de la région $region.";
  let desc = undefined;

  if (room) {
    // type
    let formattedType;
    if (ROOM_TYPES[room.type]) {
      formattedType = intl.formatMessage(
        { id: "app.constants.room.type.title" },
        { type: room.type }
      );
    }

    if (formattedType) {
      title = `Location ${formattedType}`;
      desc = `Découvrez notre ${formattedType}`;
    }

    // city
    const city = room.city;
    if (city) {
      title = `${title ? title : ""} à ${city}`;
      desc = `${desc ? desc : ""} à ${city}`;
    }
    // departement
    const depObject = room.zipcode
      ? DEPARTMENT_LIST[room.zipcode.slice(0, 2)]
      : undefined;
    const deprtmentName = depObject ? depObject.name : undefined;
    if (deprtmentName) {
      title = `${title ? title : ""}  (${deprtmentName})`;
    }

    // capacity
    const maxCapacity = room.maxCapacity;

    if (maxCapacity) {
      const sentence = intl.formatMessage(
        { id: "app.components.room.maxCapacity.label" },
        { capacity: maxCapacity }
      );
      desc = desc ? `${desc} ${sentence}` : sentence;
    }

    // region
    const regionName = depObject ? depObject.region : undefined;
    desc = regionName
      ? `${desc} en plein coeur de la région ${regionName}.`
      : desc;

    if (title && desc) {
      if (title) {
        seoContentList.push(<title key="title">{title}</title>);
        seoContentList.push(
          <meta key="twitterTitle" name="twitter:title" content={title} />
        );
        seoContentList.push(
          <meta key="graphTitle" property="og:title" content={title} />
        );
      }
      if (desc) {
        seoContentList.push(
          <meta key="description" name="description" content={desc} />
        );
        seoContentList.push(
          <meta
            key="twitterDescription"
            name="twitter:description"
            content={desc}
          />
        );
        seoContentList.push(
          <meta
            key="graphDescription"
            property="og:description"
            content={desc}
          />
        );
      }
    }
  }

  if (seoContentList.length > 0) {
    return seoContentList;
  }

  // contenu par défaut
  return getContentRelayOnConfiguration({
    configuration,
    page: SEO_PAGE_VALUE_ROOM,
  });
}

import {
  ROOM_RANKING_TYPE_EPI,
  ROOM_RANKING_TYPE_CITYBREAK,
} from "@gdf/resources/src/constants/room";

type RoomHasRankingParam0Type = {
  room: ReturnType<typeof import("../../../normalizers/room").roomParseData>;
};

/**
 * Retourne vrai si l'hébergement a des épis.
 */
const roomHasRanking = ({ room }: RoomHasRankingParam0Type): boolean => {
  return (
    (ROOM_RANKING_TYPE_EPI === room.ranking.type && room.ranking.value > 0) ||
    (ROOM_RANKING_TYPE_CITYBREAK === room.ranking.type &&
      undefined !== room.ranking.value)
  );
};

export default roomHasRanking;

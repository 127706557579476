// libraries
import {
  get,
  getFirstDefinedVar,
  convertSpinalCaseToPascalCase,
} from "@gdf/shared/src/libraries";
import {
  fromString,
  fromDate,
} from "@gdf/resources/src/libraries/EnhancedDate";

// helpers
import { roomGetBestColor } from "../../helpers/room";

// normalizers
import { parseUserData } from "../user";

// constants
import {
  ROOM_OPTION_REQUIRE_TYPES,
  ROOM_OPTION_STRATEGIES,
  ROOM_STATES,
  ROOM_HANDICAPS,
  ROOM_TYPES,
  ROOM_CATEGORIES,
  ROOM_NATURES,
  ROOM_RANKING_TYPES,
  ROOM_RANKING_TYPE_EPI,
  ROOM_RANKING_TYPE_CITYBREAK,
  ROOM_TROPHIES,
} from "../../constants/room";

type RoomParseDataReturnType = {
  reference: string;
  parentReference: string;
  channelIdList: string[];
  ranking: {
    type: keyof typeof ROOM_RANKING_TYPES;
    value:
      | number
      | keyof typeof import("@gdf/resources/src/constants/room")["ROOM_CITYBREAK_RANKINGS"];
  };
  star: number;
  slug: string;
  channelId: string;
  about: string;
  title: string;
  surface: number;
  type: keyof typeof ROOM_TYPES;
  infos: string;
  bestColor: string;
  name: string;
  children: RoomParseDataReturnType[];
  categoryList: Array<keyof typeof ROOM_CATEGORIES>;
  mainCategory: string;
  reviews: number;
  rate: number;
  beds: number;
  chambers: number;
  minCapacity: number;
  maxCapacity: number;
  pictureList: Array<{
    urls: {
      mini: string;
      small: string;
      medium: string;
      large: string;
      crop: string;
    };
    caption: string;
  }>;
  movieList: Array<{ uri: string }>;
  nature: keyof typeof ROOM_NATURES;
  distance: number;
  isPro: boolean;
  code?: string;
  pieceList: Array<{
    name: string;
    about: string;
    surface: number;
    floor: string;
    beds90;
    beds140;
    beds160;
    beds180;
    beds200;
    bedsOverlaid;
  }>;
  trophyList: Array<{
    id: keyof typeof import("@gdf/resources/src/constants/room").ROOM_TROPHIES;
  }>;
  arePetsAllowed: boolean;
  hostTable: number;
  customer;
  manager;
  city: string;
  zipcode: string[5];
  lat: number;
  lng: number;
  handicapList: (keyof typeof ROOM_HANDICAPS)[];
  featureListByType: Array<any>;
  optionList: Array<{
    id?: number | string;
    name: string;
    about: string;
    required: keyof typeof ROOM_OPTION_REQUIRE_TYPES;
    strategy: keyof typeof ROOM_OPTION_STRATEGIES;
    price: number;
    isBookable: boolean;
  }>;
  state: keyof typeof ROOM_STATES;
  recentlyAdded: boolean;
  registrationNumber: string;
  website: string;
  displayMap: boolean;
};

type RoomParseDataParam0Type = {
  rawResponseData: object;
  featureIdListByType: Array<string>;
  features: { [featureId: string]: any };
};

/**
 * Parse la clé `data` de la réponse API.
 */
const roomParseData = ({
  rawResponseData,
  features,
  featureIdListByType,
}: RoomParseDataParam0Type): RoomParseDataReturnType => {
  const featureTypeList = Object.keys(featureIdListByType);

  const rankingType =
    null == get(rawResponseData, "epi") && get(rawResponseData, "ranking")
      ? ROOM_RANKING_TYPE_CITYBREAK
      : get(rawResponseData, "metas.rankingType");
  let rankingValue = null;
  switch (rankingType) {
    case ROOM_RANKING_TYPE_EPI: {
      rankingValue = Number(get(rawResponseData, "epi"));
      break;
    }

    case ROOM_RANKING_TYPE_CITYBREAK: {
      rankingValue = get(rawResponseData, "ranking");
      break;
    }
  }

  return {
    reference: get(rawResponseData, "reference"),
    parentReference: get(rawResponseData, "parentRef", null),
    ranking: {
      type: rankingType,
      value: rankingValue,
    },
    star: Number(get(rawResponseData, "star", "0")),
    slug: get(rawResponseData, "slug"),
    channelId: get(rawResponseData, "channelId"),
    about: get(rawResponseData, "about"),
    title: get(rawResponseData, "title"),
    surface: Number(get(rawResponseData, "surface", "0")),
    type: get(rawResponseData, "type"),
    infos: get(rawResponseData, "infos"),
    trophyList: get(rawResponseData, "trophies", []).map(
      (trophyName) => ROOM_TROPHIES[trophyName]
    ),
    bestColor: roomGetBestColor({
      categoryList: get(rawResponseData, "categories", []),
    }),
    name: get(rawResponseData, "name"),
    children: get(rawResponseData, "childs", []).map((roomChild) =>
      roomParseData({
        rawResponseData: roomChild,
        features,
        featureIdListByType,
      })
    ),
    categoryList: get(rawResponseData, "categories", []),
    mainCategory: get(rawResponseData, "metas.mainCategory"),
    ...get(rawResponseData, "roomFeatures", []).reduce(
      (accumulator, roomFeature) => {
        const roomFeatureId = get(roomFeature, "id");

        const featureType = featureTypeList.find((featureType) =>
          featureIdListByType[featureType].includes(roomFeatureId)
        );

        // Fonctionnalité récupérer via la configuration.

        const feature = features[roomFeatureId];

        if (undefined !== featureType && undefined !== feature) {
          if (!Array.isArray(accumulator.featureListByType[featureType])) {
            accumulator.featureListByType[featureType] = [];
          }

          accumulator.featureListByType[featureType].push({
            id: feature.id,
            type: featureType,
            name: feature.name,
            distance: roomFeature.distance,
            icon:
              null !== feature.icon
                ? convertSpinalCaseToPascalCase(`icon-${feature.icon}-o`)
                : null,
          });
        }

        return accumulator;
      },
      {
        featureListByType: {},
      }
    ),
    reviews: Number(get(rawResponseData, "metas.commentStats.qte", "0")),
    rate: Number(get(rawResponseData, "metas.commentStats.average", "0")),
    beds: Number(get(rawResponseData, "beds", "0")),
    chambers: Number(get(rawResponseData, "chambers", "0")),
    minCapacity: Number(get(rawResponseData, "minCapacity", "0")),
    maxCapacity: Number(get(rawResponseData, "maxCapacity", "0")),
    pictureList: get(rawResponseData, "pictures", []).map((rawPicture) => ({
      urls: get(rawPicture, "urls"),
      caption: get(rawPicture, "caption"),
    })),
    movieList: get(rawResponseData, "movies", [])
      .filter((rawMovie) => rawMovie !== "")
      .map((rawMovie) => ({
        uri: rawMovie,
      })),
    nature: get(rawResponseData, "nature"),
    distance: Number(get(rawResponseData, "metas.distance", "0")),
    pieceList: get(rawResponseData, "pieces", []).map((piece) => ({
      name: get(piece, "name"),
      about: get(piece, "about"),
      surface: parseFloat(get(piece, "surface")),
      floor: parseInt(get(piece, "floor")),
      beds80: get(piece, "beds80"),
      beds90: get(piece, "beds90"),
      beds120: get(piece, "beds120"),
      beds140: get(piece, "beds140"),
      beds160: get(piece, "beds160"),
      beds180: get(piece, "beds180"),
      beds200: get(piece, "beds200"),
      bedsOverlaid: get(piece, "bedsOverlaid"),
    })),
    recentlyAdded:
      fromString(get(rawResponseData, "created"), "Y-m-d H:i:s").monthDiff(
        fromDate()
      ) <= 6,
    arePetsAllowed: Boolean(Number(get(rawResponseData, "pets", "0"))),
    hostTable: Number(get(rawResponseData, "hostTable", "0")) || 0,
    customer: parseUserData({
      rawResponseData: get(rawResponseData, "customer"),
    }),
    manager: parseUserData({
      rawResponseData: get(rawResponseData, "manager"),
    }),
    city: get(rawResponseData, "city"),
    zipcode: get(rawResponseData, "zipcode"),
    lat: get(rawResponseData, "lat", null),
    lng: get(rawResponseData, "lng", null),
    handicapList: get(rawResponseData, "handicaps", []),
    optionList: Object.entries<{
      id?: number | string;
      name: string;
      about: string;
      required: keyof typeof ROOM_OPTION_REQUIRE_TYPES;
      strategy: keyof typeof ROOM_OPTION_STRATEGIES;
      amount: string;
      isBookable: boolean;
    }>(get(rawResponseData, "options", {})).reduce<
      Array<{
        id: string;
        name: string;
        about: string;
        required: keyof typeof ROOM_OPTION_REQUIRE_TYPES;
        strategy: keyof typeof ROOM_OPTION_STRATEGIES;
        price: number;
        isBookable: boolean;
      }>
    >((optionList, [optionId, option]) => {
      if (option.strategy) {
        optionList.push({
          id: getFirstDefinedVar(option.id, optionId),
          name: get(option, "name"),
          about: get(option, "about"),
          required: get(option, "required"),
          strategy: get(option, "strategy"),
          price: Number(get(option, "amount")),
          isBookable: get(option, "isBookable", true),
        });
      }

      return optionList;
    }, []),
    state: get(rawResponseData, "state"),
    registrationNumber: get(rawResponseData, "registrationNumber"),
    website: get(rawResponseData, "website"),
    channelIdList: get(rawResponseData, "channels", []).map(
      (channel) => channel.channelId
    ),
    isPro: Boolean(get(rawResponseData, "isPro", false)),
    code: get(rawResponseData, "code", null),
    displayMap: Boolean(Number(get(rawResponseData, "displayMap", "1"))),
  };
};

export default roomParseData;

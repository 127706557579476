// dep
import React from "react";

// constant
import { SEO_PAGE_VALUE_CONTENT } from "@gdf/resources/src/constants/seo";

// helpers
import getContentRelayOnConfiguration from "./getContentRelayOnConfiguration";

/**
 * Détermine les informations en fonction d'une Content
 * @param configuration
 * @param page
 */
export default function getContentRelayOnContent({ content, configuration }) {
  const title = content?.seoTitle;
  const description = content?.seoDesc;
  const seoContentList = [];

  if (title) {
    seoContentList.push(<title key="title">{title}</title>);
    seoContentList.push(
      <meta key="twitterTitle" name="twitter:title" content={title} />
    );
    seoContentList.push(
      <meta key={"graphTitle"} property="og:title" content={title} />
    );
  }
  if (description) {
    seoContentList.push(
      <meta key="description" name="description" content={description} />
    );
    seoContentList.push(
      <meta
        key="twitterDescription"
        name="twitter:description"
        content={description}
      />
    );
    seoContentList.push(
      <meta key="graphTitle" property="og:description" content={description} />
    );
  }
  if (seoContentList.length > 0) {
    return seoContentList;
  }

  // contenu par défaut
  return getContentRelayOnConfiguration({
    configuration,
    page: SEO_PAGE_VALUE_CONTENT,
  });
}
